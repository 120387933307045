// change this to static.gpcaustralia.com when domain redelegated
// and pointed at CF host
const STATIC_URL_HOST = 'static.gpcaustralia.com';

export function staticUrl(path) {
  let hostPath = `${STATIC_URL_HOST}/${path}`;
  while (hostPath.indexOf('//') !== -1) {
    hostPath = hostPath.replace('//', '/');
  }
  return `https://${hostPath}`;
}

export function decodeEmail(encodedEmail) {
  let decodedEmail = '';
  for (let i = 0; i < encodedEmail.length; i++) {
    const encCharCode = encodedEmail.charCodeAt(i);
    // assume all lower case
    if (encCharCode >= 97 && encCharCode <= 122) {
      // rot13
      const decCharCode = ((encCharCode - 97 + 13) % 26) + 97;
      decodedEmail += String.fromCharCode(decCharCode);
    } else {
      decodedEmail += encodedEmail[i];
    }
  }
  decodedEmail = decodedEmail.replace('$', '@').replace('_', '.');
  return decodedEmail;
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
