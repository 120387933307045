import './App.css';
import { Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Home from './Home';
import Join from './Join';
import Members from './Members';
import UpdateMembership from './UpdateMembership';
import Records from './Records';
import Resources from './Resources';
import ContactUs from './ContactUs';

// const stripePromise = loadStripe('pk_test_YfvrMUnWDquo8czFOIebv8Dm005O3b7o4b');
const stripePromise = loadStripe('pk_live_6ossG5F6Q8RyKKTApZpmIY9L00MT8jxYs9');

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/join' element={<Join />} />
        <Route path='/members' element={<Members />} />
        <Route path='/update-membership/:key' element={<UpdateMembership />} />
        <Route path='/records' element={<Records />} />
        <Route path='/resources' element={<Resources />} />
      </Routes>
    </Elements>
  );
}

export default App;
