import { Menu } from 'semantic-ui-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './gpc-logo.png';

export default function MenuBar() {
  const [activeItem, setActiveItem] = useState();
  const navigate = useNavigate();

  const handleItemClick = (_e, { name }) => {
    setActiveItem(name);
    navigate(`/${name}`);
  };

  return (
    <Menu fluid stackable borderless>
      <Menu.Item name='' active={activeItem === ''} onClick={handleItemClick}>
        <img alt='logo' src={logo} />
      </Menu.Item>

      <Menu.Item
        name='members'
        active={activeItem === 'members'}
        onClick={handleItemClick}
      >
        Members
      </Menu.Item>

      <Menu.Item
        name='records'
        active={activeItem === 'records'}
        onClick={handleItemClick}
      >
        Records
      </Menu.Item>

      <Menu.Item
        name='resources'
        active={activeItem === 'resources'}
        onClick={handleItemClick}
      >
        Resources
      </Menu.Item>

      <Menu.Item
        name='contactus'
        active={activeItem === 'contactus'}
        onClick={handleItemClick}
      >
        Contact Us
      </Menu.Item>

      <Menu.Item
        name='managemembership'
        onClick={() =>
          window.open(
            'https://billing.stripe.com/p/login/9AQ3cKbwq7zvg5q6oo',
            '_blank',
            'rel=noopener noreferrer'
          )
        }
      >
        Manage Membership
      </Menu.Item>

      <Menu.Item
        position='right'
        name='join'
        active={activeItem === 'join'}
        onClick={handleItemClick}
      >
        Join GPC
      </Menu.Item>
    </Menu>
  );
}
