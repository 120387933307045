import { useEffect, useState } from 'react';
import { get } from 'aws-amplify/api';
import {
  Container,
  Input,
  Loader,
  // Message,
  Table,
} from 'semantic-ui-react';
import MenuBar from './MenuBar';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

export default function Members() {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [filter, setFilter] = useState(false);

  const handleSearch = e => {
    const value = e.target.value;
    if (value !== '') {
      setFilter(value);
    } else {
      setFilter(false);
    }
  };

  useEffect(() => {
    const getMembers = async () => {
      try {
        const restOperation = get({
          apiName: 'frontend',
          path: '/members',
        });
        const { body } = await restOperation.response;
        const data = await body.json();
        setMembers(data.members ?? []);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getMembers();
  }, []);

  return (
    <Container>
      <MenuBar />
      <Container>
        {loading ? (
          <Loader active inline='centered' />
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='3'>
                  <Input
                    icon='search'
                    placeholder='Search...'
                    onChange={handleSearch}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Membership Expires</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {members
                .sort(
                  (a, b) => a.membership_expires_at - b.membership_expires_at
                )
                .filter(
                  member =>
                    !filter ||
                    member.name?.toLowerCase().includes(filter.toLowerCase())
                )
                .map(member => (
                  <Table.Row>
                    <Table.Cell>{member.id}</Table.Cell>
                    <Table.Cell>
                      {member.name ?? `Member Id ${member.id}`}
                    </Table.Cell>
                    <Table.Cell>
                      {new Date(
                        member.membership_expires_at * 1000
                      ).toLocaleDateString()}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        )}
      </Container>
    </Container>
  );
}
