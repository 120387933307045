import { Container, Header } from 'semantic-ui-react';
import MenuBar from './MenuBar';

export default function Home() {
  return (
    <Container>
      <MenuBar />
      <Container>
        <Header as='h1'>GPC Australia</Header>
        <p>Official Affiliate Of The Global Powerlifting Committee</p>
        <Header as='h2'>Welcome</Header>
        <p>
          We are the official Australian affiliate of the Global Powerlifting
          Committee, and will provide a wide range of events around Australia.
          GPC Australia offers both Raw (knee wraps or sleeves allowed) and
          Equipped powerlifting, as well as national bench press and deadlift
          competitions.
        </p>
        <p>
          <strong>
            Please note that this site is still under development.
          </strong>
        </p>
        <p>
          Currently, please see the{' '}
          <a href='https://www.facebook.com/GPCAus'>
            GPC Australia Facebook page
          </a>
          &nbsp; for info on upcoming events, and{' '}
          <a href='https://www.openpowerlifting.org/mlist/gpc-aus'>
            Open Powerlifting
          </a>
          &nbsp; for event results.
        </p>
        <p>
          Feel free to have a look around the site, and if you have any
          questions, feel free to shoot any of our GPC Australia officials an
          email the Contact Us page.
        </p>
      </Container>
    </Container>
  );
}
