import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import { validate } from 'email-validator';
import MenuBar from './MenuBar';
import logo from './gpc-logo.png';

export default function UpdateMembership() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const { key } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getMembers = async () => {
      const data = await (
        await fetch(
          `https://l097g977ef.execute-api.us-west-2.amazonaws.com/staging/update-membership/${key}`
        )
      ).json();
      setName(data.name);
      setLoading(false);
    };

    getMembers();
  }, []);

  const updateName = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ key, name }),
      };
      console.log(requestOptions);
      // const response = await fetch(
      //   `https://l097g977ef.execute-api.us-west-2.amazonaws.com/staging/update-membership/${key}`,
      //   requestOptions
      // );
      // const data = await response.json();
      // if (data.err) {
      //   setError({
      //     header: 'Error Occurred.',
      //     message:
      //       'An error occurred updating your information, please try again!',
      //     type: 'error',
      //   });
      // } else {
      navigate('/members');
      // }
    } catch (error) {
      setError({
        header: 'Error Occurred.',
        message:
          'An error occurred updating your information, please try again!',
        type: 'error',
      });
    }
    setLoading(false);
  };

  const handleNameChange = (e, { value }) => {
    setName(value);
    if (!validate(value)) {
      setFormErrors([...new Set([...formErrors, 'name'])]);
    } else {
      setFormErrors(formErrors.filter(error => error !== 'name'));
    }
  };

  const handleSubmit = () => {
    const submissionErrors = [];
    if (!name) {
      submissionErrors.push('name');
    }
    if (submissionErrors.length > 0) {
      setFormErrors(submissionErrors);
    } else {
      setLoading(true);
      updateName();
    }
  };

  return (
    <Container>
      <MenuBar />
      <Container>
        <Grid
          textAlign='center'
          style={{ height: '75vh' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' color='teal' textAlign='center'>
              <Image src={logo} />
            </Header>
            <Form
              onSubmit={handleSubmit}
              size='large'
              error={error.type === 'error'}
            >
              <Segment>
                <Form.Input
                  error={formErrors.includes('name')}
                  fluid
                  id='name'
                  name='name'
                  value={name}
                  onChange={handleNameChange}
                />
                <Message content='Your name will default to the one used to create and pay for the membership, please update this if it is incorrect.' />
                <Message error header={error.header} content={error.message} />
                <Button
                  disabled={formErrors.length > 0 || loading}
                  loading={loading}
                  fluid
                  secondary
                  size='large'
                >
                  Update Name
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
}
